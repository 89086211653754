import React, { useState, useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Styled from '../../utils/styled';
import getChildren from '../../utils/children';
import 'intersection-observer'; // for pollyfill

const RawBoxBlock = ({ box, noCollapseMargin, minHeight, classes, content, setBannerCallback, contentHeight }, ref) => {
	const [toggle, setToggle] = useState(false);
	const [rootNode, setRootNode] = useState(null);
	const elRef = useCallback(
		node => {
			if (node !== null) {
				setRootNode(node);
				if (setBannerCallback) setBannerCallback(node);
			}
		},
		[setBannerCallback]
	);
	useEffect(() => {
		if (!rootNode || !setBannerCallback) return;
		const options = {
			threshold: 0.5,
		};
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				setToggle(entry.isIntersecting);
				// return setToggle(true);
				// return setToggle(false);
			});
		}, options);
		observer.observe(rootNode);
		// eslint-disable-next-line consistent-return
		return () => observer.unobserve(rootNode);
	}, [rootNode]);

	// useEffect(() => {
	// 	console.log(classes);
	// }, [classes]);

	// if (contentHeight) return (
	// 	<Box
	// 		{...box}
	// 		className={clsx(classes.root, classes.initTransition, classes.initSlideUp, classes.initFadeIn, classes.slideContentContainer, {
	// 			[classes.noCollapsedMargin]: noCollapseMargin,
	// 			[classes.runFadeIn]: toggle,
	// 			[classes.runSlideUp]: toggle,
	// 		})}
	// 		{...(minHeight ? { style: { minHeight } } : {})}
	// 		ref={elRef}
	// 		style={{ '--content-height': `${contentHeight}px` }}
	// 	>
	// 		<div className={classes.initContentSlideUp}>
	// 			{getChildren(content)}
	// 		</div>
	// 	</Box>
	// )

	return (
		<Box
			{...box}
			className={clsx(classes.root, {
				[classes.initTransition]: Boolean(setBannerCallback),
				[classes.initSlideUp]: Boolean(setBannerCallback),
				[classes.initFadeIn]: Boolean(setBannerCallback),
				[classes.initContentTransition]: Boolean(setBannerCallback),
				[classes.noCollapsedMargin]: noCollapseMargin,
				[classes.runFadeIn]: toggle,
				[classes.runSlideUp]: toggle,
			})}
			{...(minHeight ? { style: { minHeight } } : {})}
			ref={elRef}
		>
			{getChildren(content)}
		</Box>
	);
};

export default StyleLoader(
	RawBoxBlock,
	makeStyles(
		theme => ({
			root: {},
			noCollapsedMargin: {
				overflow: 'hidden',
			},
		}),
		{ name: 'SWBox' }
	)
);
