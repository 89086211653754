export default theme => ({
	...theme,
	palette: {
		primary: {
			main: theme.palette.primary.main
		},
		secondary: {
			main: theme.palette.secondary.main
		},
		type: 'dark'
	}
});

