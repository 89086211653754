import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/styles';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Button from '@material-ui/core/Button';
// import yellow from '@material-ui/core/colors/yellow';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import getChildren from '../../utils/children';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneIcon from '@material-ui/icons/Phone';

const RecordHeader = (props, ref) => {
	const {
		title,
		titleVariant,
		titleColor,
		subTitle,
		subTitleVariant,
		subTitleColor,
		subTitleAbove,
		content,
		classes,
		personProps,
		hasAuthor,
		verticalContent = false,
		stripe,
		stripeVariant = "caption",
		stripeColor = "default",
		phone, email
	} = props;

	const [toggle, setToggle] = useState(false);
	const el = useRef(null);

	const theme = useTheme();

	// console.log('props', props);

	const { iconButtons, normalButtons, contentItems, images } = content.reduce(
		(acc, value) => {
			const type = value.baseType || value.type;
			switch (type) {
				case 'raw/icon-button':
					acc.iconButtons.push(value);
					break;
				case 'raw/button':
				case 'dialog':
					acc.normalButtons.push(value);
					break;
				case 'raw/image':
				case 'image':
					acc.images.push(value);
					break;
				default:
					acc.contentItems.push(value);
					break;
			}
			return acc;
		},
		{ iconButtons: [], normalButtons: [], contentItems: [], images: [] }
	);

	useEffect(() => {
		const node = el.current;
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) return setToggle(true);
				return setToggle(false);
			});
		});
		observer.observe(node);
		return () => observer.unobserve(node);
	}, []);

	const HeaderTitle = () => {
		const recordTitle = title.split(",");
		const titleDecoration = recordTitle.length > 1 ? `,${recordTitle.splice(1)}` : "";
		// console.log(recordTitle)
		if (subTitleAbove == 1) {
			return (
				<>
					{subTitle && (
						<Typography className={classes.subTitle} variant={subTitleVariant} color={subTitleColor}>
							{subTitle}
						</Typography>
					)}
					<Typography className={classes.title} variant={titleVariant} color={titleColor}>
						{recordTitle}<span className={classes.titleDecoration}>{titleDecoration}</span>
					</Typography>
				</>
			);
		}
		return (
			<>
				<Typography className={classes.title} variant={titleVariant} color={titleColor}>
					{recordTitle}<span className={classes.titleDecoration}>{titleDecoration}</span>
				</Typography>
				{subTitle && (
					<Typography className={classes.subTitle} variant={subTitleVariant} color={subTitleColor}>
						{subTitle}
					</Typography>
				)}
			</>
		);
	};

	const getInitials = avatarTitle => {
		const initialsArray = avatarTitle.split(' ').map(name => name[0].toUpperCase());
		return `${initialsArray[0]}${initialsArray[initialsArray.length - 1]}`;
	};

	const avatarContent = personTitle => {
		if (personTitle && !personProps[0].image.length) return personTitle[0].toUpperCase();
		return '';
	};

	return (
		<Box
			className={clsx(classes.root, classes.initTransition, classes.initFadeIn, classes.initSlideUp, {
				[classes.dark]: theme.palette.type == 'dark',
				[classes.light]: theme.palette.type == 'light',
				[classes.runFadeIn]: toggle,
				[classes.runSlideUp]: toggle,
			})}
			ref={el}
		>
			{stripe && <Typography variant={stripeVariant} color={stripeColor} component="div" className={clsx("not-animated", classes.headerStripe)}>{stripe}</Typography>}
			<div className={clsx(classes.inner, { [classes.withImages]: images.length > 0 })}>
				{!!images.length && <div className={classes.images}>{getChildren(images)}</div>}
				<div className={classes.text}>
					<HeaderTitle />
					{email && <Button variant='text' className={classes.email} href={`mailto:${email}`}><EmailOutlinedIcon/> <span>{email}</span></Button>}
					{phone && <Button variant='text' className={classes.phone} href={`tel:+1${phone.replace(/[^0-9]/g, '')}`}><PhoneIcon/> <span>{phone}</span></Button>}
					{!!contentItems.length && (
						<Box
							className={clsx(theme.globalStyles.margins.top.small, {
								[classes.content]: !hasAuthor && !verticalContent,
								[classes.verticalContent]: !hasAuthor && verticalContent,
								[classes.withAuthor]: hasAuthor,
							})}
						>
							{hasAuthor &&
								contentItems.map((item, i) => {
									return (
										// TODO just send avatar over as a child and maybe use chip instead for linking
										// <Button contained key={item.id} href="Jackie-Ortega">
										<div className={classes.avatarContainer} key={item.id}>
											{/* <Avatar alt={item.text.children} src={avatarImages[i]} className={classes.avatar}>
												{avatarContent(item.text.children)}
											</Avatar>
											<div className={classes.authorTitleWrapper}>{getChildren([item])}</div> */}
											<Chip
												variant="outlined"
												size="medium"
												avatar={
													<Avatar alt={personProps[i].title} src={personProps[i].image}>
														{avatarContent(personProps[i].title)}
													</Avatar>
												}
												label={item.text.children}
												clickable
												component="a"
												href={personProps[i].link}
											/>
										</div>
										// </Button>
									);
								})}
							{!hasAuthor && getChildren(contentItems)}
						</Box>
					)}
					{!!normalButtons.length && (
						<Box className={clsx(classes.normalButtons, theme.globalStyles.margins.top.medium)}>
							{getChildren(normalButtons)}
						</Box>
					)}
					{!!iconButtons.length && <Box className={classes.iconButtons}>{getChildren(iconButtons)}</Box>}
				</div>
			</div>
		</Box>
	);
};

export default ThemeLoader(
	StyleLoader(
		RecordHeader,
		makeStyles(
			theme => ({
				root: {
					color: theme.palette.text.primary, // .contrastText,
					justifyContent: 'flex-end',
					position: 'relative',
					'--background-overlay':
						'linear-gradient(to bottom, var(--background-overlay-color), var(--background-overlay-color))',
					'--background-overlay-color': 'transparent',
				},
				titleDecoration: {
					fontSize: '2rem',
				},
				initFadeIn: {},
				runFadeIn: {},
				initSlideUp: {},
				runSlideUp: {},
				initTransition: {},
				dark: {
					'&.WithBackground-image': {
						'--background-overlay-color': 'rgba(0, 0, 0, 0.4)',
					}
				},
				light: {
					'&.WithBackground-image': {
						'--background-overlay-color': 'rgba(255, 255, 255, .4)',
					}
				},
				title: {
					lineHeight: 1.2,
				},
				inner: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'flex-end',
				},
				withImages: {
					alignItems: 'flex-end',
					justifyContent: 'flex-start',
					flexDirection: 'row',
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						justifyContent: 'flex-end',
						alignItems: 'center',
					},
				},
				text: {
					justifyContent: 'flex-end',
					display: 'flex',
					flexDirection: 'column',
					flex: '1 1 auto',
					alignItems: 'flex-start',
				},
				images: {
					justifyContent: 'flex-end',
					display: 'flex',
					flexDirection: 'column',
					flex: '0 0 350px',
					alignItems: 'flex-start',
					marginRight: theme.spacing(4),
					[theme.breakpoints.down('sm')]: {
						flex: '0 1 350px',
						maxWidth: '100%',
					},
				},
				subTitle: {
					// color: yellow[100],
				},
				withAuthor: {
					display: 'flex',
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
					},
					[theme.breakpoints.up('sm')]: {
						flexDirection: 'row',
					},
				},
				authorTitleWrapper: {
					display: 'flex',
					alignItems: 'center',
				},
				content: {
					display: 'flex',
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
					},
					[theme.breakpoints.up('sm')]: {
						flexDirection: 'row',
						'& > * + *:before': {
							content: '"|"',
							marginLeft: theme.spacing(1),
							marginRight: theme.spacing(1),
						},
					},
				},
				verticalContent: {
					display: 'flex',
					flexDirection: 'column'
				},
				avatar: {
					width: '30px',
					height: '30px',
					marginRight: theme.spacing(1),
					fontSize: '1em',
					color: '#fff',
					fontWeight: 'bold',
				},
				avatarContainer: {
					display: 'flex',
					flexDirection: 'row',
					[theme.breakpoints.down('xs')]: {
						marginTop: theme.spacing(1),
					},
					marginRight: theme.spacing(2),
				},
				iconButtons: {
					position: 'absolute',
					right: theme.spacing(8),
					bottom: theme.spacing(8),
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
					justifyContent: 'flex-end',
				},

				normalButtons: {
					'& > * + *': {
						marginLeft: theme.spacing(0.5),
					},
				},
				headerStripe: {
					position: 'absolute',
					top: 18,
					right: -22,
					position: 'absolute',
					transform: 'rotate(45deg)',
					width: 100,
					textAlign: 'center',
					transformOrigin: '50% 50%'
				},
				email: {
					// [theme.breakpoints.up('md')]: { marginLeft: '-8px', },
					'& [class*=MuiButton-label]': { textTransform: 'none', ...theme.typography.h4, '& span': { fontSize: '80%' } },
					'& [class*=MuiSvgIcon-root]': { marginRight: '8px' }
				}, 
				phone: {
					// [theme.breakpoints.up('md')]: { marginLeft: '-8px', },
					'& [class*=MuiButton-label]': { textTransform: 'none', ...theme.typography.h4, '& span': { fontSize: '80%' } },
					'& [class*=MuiSvgIcon-root]': { marginRight: '8px' }
				}
			}),
			{ name: 'SWRecordHeader' }
		)
	)
);
