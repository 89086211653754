import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import BoxBlock from '../box/block';
import { Breakpoint } from '../../utils/useBreakpoint';
import ReactGA from 'react-ga';

const RootContainer = (props, ref) => {
	const { className, initialBreakpoint } = props;

	useEffect(() => {
		ReactGA.initialize('UA-8868098-2');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Breakpoint initialValue={initialBreakpoint}>
			<BoxBlock {...props} ref={ref} />
		</Breakpoint>
	);
};

export default StyleLoader(
	RootContainer,
	makeStyles(
		theme => ({
			root: {
				'& > .MuiGrid-root': {
					[`&:not(.${theme.globalStyles.base.marginBottom}), &.${theme.globalStyles.base.dividerBottom}`]: {
						marginBottom: 0,
					},
					[`&:not(.${theme.globalStyles.base.marginTop}), &.${theme.globalStyles.dividers.top}`]: {
						marginTop: 0,
					},
					[`&:not(.${theme.globalStyles.base.width})`]: {
						marginLeft: 0,
						marginRight: 0,
						width: '100%',
					},
				},
				// https://github.com/mui-org/material-ui/issues/16367#issuecomment-569829175 accessibility feature
				'@media (prefers-reduced-motion: reduce)': {
					'& *': {
						animationDuration: '0.001ms !important',
						animationIterationCount: '1 !important',
						transitionDuration: '0.001ms !important',
					},
				},
			},
		}),
		{ name: 'SWPageRoot' }
	)
);
