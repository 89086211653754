export default theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: theme.spacing(2),
		'& > .MuiBox-root': {
			display: 'grid',
			gridTemplateColumns: 'repeat(3, 1fr)',
			flex: 3,
			'& > .MuiTypography-root': {
				gridColumn: '1 / 4',
				padding: '6px 8px'
			},
			'& > .MuiBox-root': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				'& > .MuiButton-root': {
					minWidth: 0
				}
			},
			'&:last-child': {
				gridTemplateColumns: '1fr',
				flex: 1,
				'& > .MuiTypography-root': {
					gridColumn: '1 / 2'
				}
			}
		}
	}
})
