export default theme => ({
	root: {
		[theme.breakpoints.down('md')]: {
			'& .MuiButton-label .MuiTypography-root.MuiTypography-button': {
				...theme.typography.h6,
				textTransform: 'uppercase',
				// color: theme.palette.primary.main
			}
		}
	}
})
