import HeaderBanner from './header-banner';

export default theme => {
	const styles = HeaderBanner(theme);

	['xs', 'sm', 'md', 'lg', 'xl'].forEach(v => {
		const b = theme.breakpoints.only(v);
		styles.root[b].minHeight *= 0.6;
	});
	styles.root[theme.breakpoints.only('xl')]['& .MuiTypography-root, & [class*=MuiTypography-root-]'].maxWidth = 1600;

	return styles;
};
