import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
	root: {
		'&:before': {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			content: '""',
			background: '#fff',
			borderRadius: '50%',
			zIndex: 0,
		},
		'&:after': {
			opacity: 0,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			position: 'absolute',
			zIndex: 1,
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
			}),
			content: '""',
			'@media (hover: none)': {
				content: 'none',
			},
		},
		boxShadow: theme.shadows[4],
		'& > *': {
			zIndex: 2,
		},
		'&:hover:after': {
			opacity: 1,
		},
	},
	colorPrimary: {
		'&:after': {
			backgroundColor: fade(
				theme.palette.primary.main,
				theme.palette.action.hoverOpacity
			),
		},
	},
	colorSecondary: {
		'&:after': {
			backgroundColor: fade(
				theme.palette.secondary.main,
				theme.palette.action.hoverOpacity
			),
		},
	},
});
