import React, { useCallback, useMemo, useContext, useState, useEffect } from 'react';
import { Calendar, Views, Toolbar, momentLocalizer } from 'react-big-calendar';
import { Context as BigCalendarStyleContext } from 'react-big-calendar/src/Styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import * as dates from 'react-big-calendar/src/utils/dates';
import moment from 'moment-timezone';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { navigate } from 'react-big-calendar/src/utils/constants';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '../../components/DialogContent';
import useBreakpoint from '../../utils/useBreakpoint';
import AllEvents from './events';
import DialogTitle from '../../components/DialogTitle';
import DialogActions from '../../components/DialogActions';
import { Context as QueryContext } from '../../utils/query';

const allViews = Object.values(Views);
const localizer = momentLocalizer(moment);

const DateRange = ({ start, end, allDay, forDate }) => {
	if (forDate) {
		if (start.format('YYYYMMDD') == end.format('YYYYMMDD')) {
			return start.format('MMMM D, YYYY');
		}
		if (start.format('YYYYMM') == end.format('YYYYMM')) {
			return `${start.format('MMMM D')} - ${end.format('D, YYYY')}`;
		}
		if (start.format('YYYY') == end.format('YYYY')) {
			return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;
		}
		return `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`;
	}

	if (allDay) {
		return `All day`;
	}

	if (start.format('HHmm') == end.format('HHmm')) return start.format('h:mma');
	return `${start.format('h:mma')} - ${end.format('h:mma')}`;
};

const SWAgendaDate = props => (
	<>
		{moment(props.day).format('ddd')}
		<br />
		{moment(props.day).format('MMM D')}
	</>
);

const SWCalendarEventInfo = props => {
	const linkContext = useContext(QueryContext);
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby={`calendar-${props.id}-info`}
			aria-describedby={`calendar-${props.id}-description`}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id={`calendar-${props.id}-info`} onClose={props.handleClose}>
				<DateRange start={props.start} end={props.end} allDay={props.allDay} forDate />
			</DialogTitle>
			<DialogContent>
				<DialogContentText id={`calendar-${props.id}-description`} variant="h4">
					{props.title}
				</DialogContentText>
				<DialogContentText variant="subtitle1">
					<DateRange forDate={false} start={props.start} end={props.end} allDay={props.allDay} />
				</DialogContentText>
				{props.location && <DialogContentText variant="subtitle1">{props.location}</DialogContentText>}
				{props.link && (
					<Button
						variant="contained"
						color="primary"
						href={props.link.preserveQuery ? linkContext.extend(props.link.href) : props.link.href}
						target={props.link.openInNewWindow ? '_blank' : '_self'}
					>
						View Details
					</Button>
				)}
			</DialogContent>
			<DialogActions onClose={props.handleClose} />
		</Dialog>
	);
};

const SWCalendar = (props, ref) => {
	const theme = useTheme();
	const currentMedia = useBreakpoint();
	const isSmallMedia = currentMedia == 'xs' || currentMedia == 'sm';
	const [lastState, setLastState] = useState(isSmallMedia);
	const [getNow, setGetNow] = useState(() => () => new Date(props.initialNow));

	useEffect(() => {
		setLastState(isSmallMedia);
	}, [isSmallMedia]);

	useEffect(() => {
		setGetNow(() => () => new Date());
	}, []);

	const ToolbarComponent = useCallback(
		innerProps => <SWCalendarToolbar {...innerProps} classes={props.classes} props={props} />,
		Object.entries(props.classes).reduce((a, b) => a.concat(b))
	);
	const EventComponent = useCallback(
		innerProps => <SWCalendarEvent {...innerProps} classes={props.classes} props={props} />,
		[props.classes.eventLink]
	);

	const CalendarEvents = useMemo(() => {
		return props.calendarItems.map(
			({ id, title, startTime, endTime, timeZone, allDay, link, openInNewWindow, location }) => ({
				id,
				title,
				start: allDay ? moment(moment.tz(startTime, timeZone).startOf('day').format('LLLL')) : moment.tz(startTime, timeZone),
				end: allDay ? moment(moment.tz(endTime, timeZone).endOf('day').format('LLLL')) : moment.tz(endTime, timeZone),
				allDay,
				link,
				openInNewWindow,
				location,
			})
		);
	}, [props.calendarItems]);

	if (lastState != isSmallMedia) return null;
	const currentView = isSmallMedia ? props.mobileView : props.desktopView;

	return (
		<Box
			className={clsx(props.classes.root, currentView == 'agenda' ? props.classes.isAgenda : null)}
			key={isSmallMedia ? props.mobileView : props.desktopView}
		>
			<Calendar
				ref={ref}
				events={CalendarEvents}
				views={isSmallMedia ? [props.mobileView] : [props.desktopView]}
				defaultView={isSmallMedia ? props.mobileView : props.desktopView}
				key={isSmallMedia ? props.mobileView : props.desktopView}
				step={60}
				showMultiDayTimes
				localizer={localizer}
				className={clsx(props.classes.calendar, currentView == 'agenda' && props.classes.isAgenda)}
				getNow={getNow}
				components={{
					toolbar: ToolbarComponent,
					event: EventComponent,
					agenda: {
						date: SWAgendaDate,
					},
				}}
			/>
		</Box>
	);
};

const SWCalendarEvent = ({ event, isAllDay, classes, isAgenda }) => {
	const [open, setIsOpen] = useState(false);
	const [setClosed] = useState(() => () => setIsOpen(false));
	const [setOpen] = useState(() => () => setIsOpen(true));

	let eventPrefix = null;
	if (!isAllDay && !event.allDay) {
		if (!isAgenda && event.start.format('mm') == '00') eventPrefix = event.start.format('ha');
		else eventPrefix = event.start.format('h:mma');
	}

	return (
		<>
			<a onClick={setOpen} className={clsx(classes.eventLink, { [classes.eventAgendaLink]: isAgenda })}>
				{isAgenda ? (
					<>
						{eventPrefix && (
							<>
								<Typography variant="body2" component="span">
									<b>{eventPrefix}</b>
								</Typography>
								<br />
							</>
						)}
						{event.title}
					</>
				) : (
					<>
						{eventPrefix && (
							<>
								{eventPrefix}
								{'\xa0'}
							</>
						)}
						<b>{event.title}</b>
					</>
				)}
			</a>
			<SWCalendarEventInfo {...event} open={open} handleClose={setClosed} />
		</>
	);
};

const SWCalendarNavigation = props => {
	const classes = useContext(BigCalendarStyleContext);
	return (
		<ToggleButtonGroup
			exclusive
			value=""
			onChange={props.navigate}
			size={props.buttonSize}
			className={clsx(classes.btnGroup, props.classes.buttonGroup, props.classes.navigation)}
		>
			<ToggleButton value={navigate.PREVIOUS}>
				<Icon color="primary" aria-label={props.messages.previous}>
					keyboard_arrow_left
				</Icon>
			</ToggleButton>
			<ToggleButton value={navigate.TODAY}>
				<Typography variant="button" color="primary">
					{props.messages.today}
				</Typography>
			</ToggleButton>
			<ToggleButton value={navigate.NEXT}>
				<Icon color="primary" aria-label={props.messages.next}>
					keyboard_arrow_right
				</Icon>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

const SWCalendarViews = props => {
	const classes = useContext(BigCalendarStyleContext);
	return props.views.length > 1 ? (
		<ToggleButtonGroup
			value={props.view}
			exclusive
			onChange={props.switchView}
			className={clsx(classes.btnGroup, props.classes.buttonGroup, props.classes.views)}
		>
			{props.views.map(view => (
				<ToggleButton key={view} value={view}>
					{props.messages[view]}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	) : null;
};

const SWCalendarToolbar = props => {
	const classes = useContext(BigCalendarStyleContext);
	const doNavigate = useCallback((event, newValue) => props.onNavigate(newValue), [props.onNavigate]);
	const doView = useCallback((event, newValue) => props.onView(newValue), [props.onView]);

	const baseProps = props.props;
	const buttonClasses = {
		buttonGroup: props.classes.toolbarButtonGroup,
		navigation: props.classes.toolbarNavigation,
		views: props.classes.toolbarViews,
	};

	const nav =
		baseProps.showNavigation != 'none' ? (
			<SWCalendarNavigation navigate={doNavigate} classes={buttonClasses} messages={props.localizer.messages} />
		) : null;
	const view = null; // baseProps.showViews != 'none' ? <SWCalendarViews navigate={doView} view={props.view} classes={buttonClasses} messages={props.localizer.messages}/> : null;

	return (
		<Box className={clsx(classes.toolbar, props.classes.toolbar)}>
			{baseProps.showNavigation == 'start' && nav}
			{baseProps.showView == 'start' && view}
			<Typography
				className={clsx(classes.toolbarLabel, props.classes.toolbarTitle)}
				variant={baseProps.titleVariant}
			>
				{props.label}
			</Typography>
			{baseProps.showView == 'end' && view}
			{baseProps.showNavigation == 'end' && nav}
		</Box>
	);
};

export default ThemeLoader(
	StyleLoader(
		SWCalendar,
		makeStyles(
			theme => ({
				root: {
					padding: theme.spacing(1),
					minHeight: 700,
					'&$isAgenda': {
						minHeight: 0,
					},
				},
				calendar: {
					minHeight: 700,
					'&$isAgenda': {
						minHeight: 0,
					},
				},
				isAgenda: {},
				toolbar: {},
				toolbarButtonGroup: {},
				toolbarNavigation: {},
				toolbarViews: {},
				toolbarTitle: {},
				toolbarActive: {},
				eventLink: {
					...theme.typography.body1,
					color: 'inherit',
					display: 'block',
					textDecoration: 'none',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					'&$eventAgendaLink': {
						color: theme.palette.primary.main,
						transition: theme.transitions.create('color'),
						'&:hover': {
							color: theme.palette.primary[theme.palette.mode],
						},
						whiteSpace: 'normal',
						overflow: 'visible',
						textOverflow: 'initial',
					},
				},
				eventAgendaLink: {},
			}),
			{ name: 'SWCalendar' }
		)
	)
);
