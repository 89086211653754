import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import SimpleBackdrop from '@material-ui/core/Modal/SimpleBackdrop';

const rem = 16;
const pxToRem = px => `${px / rem}rem`;

const ReplacedBackdrop = props => {
	// clear correctly so scrolling behavior can return
	useEffect(() => {
		const targetElement = document.scrollingElement || document.body;
		const target = targetElement.scrollTop;
		const { position, left, top, right, overflow } = targetElement.style;
		targetElement.style.position = 'fixed';
		targetElement.style.left = 0;
		targetElement.style.top = `${0 - target}px`;
		targetElement.style.right = 0;
		targetElement.style.overflow = 'hidden';
		return () => {
			Object.assign(targetElement.style, { position, left, top, right, overflow });
			targetElement.scrollTop = target;
		};
	});

	return <SimpleBackdrop {...props} />;
};

const theme = createMuiTheme({
	typography: {
		fontFamily: ['"Open Sans"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
		h1: {
			fontSize: pxToRem(96),
			fontWeight: 'bold',
			textTransform: 'uppercase',
			lineHeight: 1
		},
		h2: { fontSize: pxToRem(60) },
		h3: { fontSize: pxToRem(48), fontStyle: 'italic', lineHeight: 1.04 },
		h4: { fontSize: pxToRem(34), lineHeight: 1.17 },
		h5: {
			fontSize: pxToRem(24),
			fontWeight: 'bold',
			textTransform: 'uppercase',
		},
		h6: { fontSize: pxToRem(20), lineHeight: 1.2 },
		subtitle1: { fontSize: pxToRem(16) },
		subtitle2: {
			fontSize: pxToRem(14),
			textTransform: 'uppercase',
			fontWeight: 'bold',
		},
		body1: { fontSize: pxToRem(16) },
		body2: { fontSize: pxToRem(14) },
	},

	palette: {
		primary: { main: '#d43833' },
		secondary: { main: '#1b8fdf' },
		action: {
			imageHoverOpacity: 0.5,
		},
	},

	background: {
		default: '#fff',
	},

	props: {
		MuiModal: {
			BackdropComponent: ReplacedBackdrop,
		},
	},
});

export default responsiveFontSizes(theme);
