import yellow from '@material-ui/core/colors/yellow';
import BasicTile from './basic-tile';

export default theme => BasicTile(theme, 'min-content');

// below is for reference only
const theme = () => ({
	content: {
		opacity: 0,
		paddingTop: theme.spacing(1),
		left: '50%',
		right: 'auto',
		maxWidth: 350,
		transform: 'translate(-50%, 0)',
		zIndex: 1,
	},

	base: {
		backgroundColor: 'rgba(255, 255, 255, 0)',
		'--base-padding': `${theme.spacing(4)}px`,
		'--content-size': `calc(3.75rem + ${theme.spacing(1)}px)`,
		padding: `var(--base-padding, ${theme.spacing(4)}px)`,
		paddingTop: `var(--base-padding, ${theme.spacing(4)}px)`,
		paddingBottom: `var(--base-padding, ${theme.spacing(4)}px)`,
		flex: 1,
		[theme.breakpoints.only('xs')]: {
			'--base-padding': `${theme.spacing(2)}px`,
		},
	},

	image: {
		position: 'relative',
		zIndex: 1,
	},

	imageWrap: {
		[theme.breakpoints.only('xs')]: {
			'--extra-space': `calc(var(--base-padding, ${theme.spacing(
				2
			)}px) * -0.5)`,
			maxWidth: `calc(100% + var(--base-padding, ${theme.spacing(2)}px))`,
			'margin-top': `var(--extra-space, ${theme.spacing(-1)}px)`,
			'margin-left': `var(--extra-space, ${theme.spacing(-1)}px)`,
			'margin-right': `var(--extra-space, ${theme.spacing(-1)}px)`,
		},
	},

	imageSizer: {
		borderRadius: '100%',
		[theme.breakpoints.only('xs')]: {
			'--extra-space': `calc(var(--base-padding, ${theme.spacing(
				2
			)}px) * -0.5)`,
			maxWidth: `calc(100% + var(--base-padding, ${theme.spacing(2)}px))`,
			'margin-top': `var(--extra-space, ${theme.spacing(-1)}px)`,
			'margin-left': `var(--extra-space, ${theme.spacing(-1)}px)`,
			'margin-right': `var(--extra-space, ${theme.spacing(-1)}px)`,
		},
		// left: `var(--base-padding, ${theme.spacing(4)}px)`,
		// right: `var(--base-padding, ${theme.spacing(4)}px)`,
	},

	title: {
		position: 'relative',
		zIndex: 1,
		maxWidth: 'min-content',
		// width: '50%',
		margin: '0 auto',
		[theme.breakpoints.only('xs')]: {
			fontSize: '1.33rem',
		},
	},

	link: {},

	root: {
		position: 'relative',
		'&:before': {
			position: 'absolute',
			top: theme.spacing(1),
			bottom: theme.spacing(1),
			left: theme.spacing(1),
			right: theme.spacing(1),
			content: '""',
			backgroundColor: yellow[100],
			transition: theme.transitions.create(),
			opacity: 0,
			zIndex: 0,
		},
		'&:hover': {
			'&:before': {
				opacity: 1,
			},
		},
		'&:hover, &$link': {
			'& $title, & $content': {
				// color: theme.palette.primary.contrastText
			},
			'& $content': {
				opacity: 1,
				transform: `translate(-50%, calc(-1 * (var(--hover-space, 0px) + var(--content-size, auto))))`,
			},
			paddingTop: `calc(var(--hover-space, 0px) + var(--base-padding, ${theme.spacing(
				4
			)}px))`,
			paddingBottom: `calc(var(--hover-space, 0px) + var(--content-size, auto) + var(--base-padding, ${theme.spacing(
				4
			)}px))`,
		},
		'& $content': {
			// top: '100%',
			// width: '100%',
			top: `calc(100% - var(--base-padding, ${theme.spacing(4)}px))`,
			width: `calc(100% - (2 * var(--base-padding, ${theme.spacing(4)}px)))`,
			transform: 'translate(-50%, 0)',
		},
		'& $title': {
			transition: theme.transitions.create(),
		},
		'& .MuiTypography-h6': {
			[theme.breakpoints.only('xs')]: {
				fontSize: '0.875rem',
			},
		},
	},
});
