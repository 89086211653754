export default theme => ({
	root: {
		maxWidth: '1080px',
		position: 'relative',
		height: 'auto',
		margin: '0px auto',
		'&:before': {
			width: '100%',
			paddingTop: '56.25%',
			display: 'block',
			content: '""',
		},
		'& iframe, & video': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		'& iframe': {
			borderRadius: theme.shape.borderRadius,
		},
	},
});
