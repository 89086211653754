import React, { useState, useEffect, useRef, Component, createRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useBreakpoint from '../../utils/useBreakpoint';
import getChildren from '../../utils/children';

let getFlatChildren = (content, didResize) => {
	let r = content.map(child => (
		child.type == 'gallery-items' ? (
			getChildren([ { ...child, key: child.id, wrap: Slide, onResize: didResize } ], null, true)
		) : (
			<Slide key={child.id} node={child} onResize={didResize} />
		)
	));
	return flatten(r);
}

let flatten = array => array.reduce((acc, val) => Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val), []);

const CarouselBlock = ({ classes, slider, content, breakpoints }, ref) => {
	const [carousel, setCarousel] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(0);
	const { buttonsOnTop, bottomMargin, ...sliderRest } = slider;
	const [didResize, setDidResize] = useState(null);
	const debounce = useRef(null);
	const { xs, sm, md, lg, xl } = breakpoints;
	const bps = {
		xs,
		sm: sm || xs,
		md: md || sm || xs,
		lg: lg || md || sm || xs,
		xl: xl || lg || md || sm || xs,
	};
	const breakpoint = bps[useBreakpoint()];
	const rest = { ...sliderRest, ...breakpoint };

	let firstClientX;
	let clientX;

	const touchStart = e => {
		firstClientX = e.touches[0].clientX;
	};

	const preventTouch = e => {
		const minValue = 5; // threshold
		clientX = e.touches[0].clientX - firstClientX;

		// Vertical scrolling does not work when you start swiping horizontally.
		if (Math.abs(clientX) > minValue) {
			e.returnValue = false;

			return false;
		}
		return true;
	};

	const containerRef = useRef();

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.addEventListener('touchstart', touchStart);
			containerRef.current.addEventListener('touchmove', preventTouch, {
				passive: false,
			});
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener('touchstart', touchStart);
				containerRef.current.removeEventListener('touchmove', preventTouch, {
					passive: false,
				});
			}
		};
	});

	useEffect(() => {
		if (carousel) {
			const resize = () => {
				const c = ReactDOM.findDOMNode(carousel);
				const firstItem = c.querySelector('.slick-slide');
				if (Math.ceil(c.offsetWidth / rest.slidesToShow) != Math.ceil(firstItem.offsetWidth)) {
					requestAnimationFrame(resize);
					return;
				}
				const tgt = ReactDOM.findDOMNode(carousel).querySelectorAll('[data-item]');
				let height = 0;
				for (const item of tgt) {
					height = Math.max(item.offsetHeight, height);
				}
				let m = false;
				tgt.forEach(node => {
					if (node.dataset.height != `${height}px`) {
						m = true;
					}
					node.style.height = `${height}px`;
				});
				if (debounce.current) {
					debounce.current.resolve(m);
					debounce.current = null;
				}
			};
			const didResize = () => {
				const tgt = ReactDOM.findDOMNode(carousel).querySelectorAll('[data-item]');
				tgt.forEach(node => {
					node.dataset.height = node.style.height;
					node.style.height = ``;
				});
				requestAnimationFrame(resize);
			};
			const debouncedDidResize = event => {
				if (debounce.current) {
					if (event) return;
					return debounce.current.promise;
				}
				const dbc = {};
				debounce.current = {
					promise: new Promise((resolve, reject) => {
						dbc.resolve = resolve;
					}),
				};
				debounce.current.resolve = dbc.resolve;
				setTimeout(didResize, 16);
				if (event) return;
				return debounce.current.promise;
			};
			resize();
			window.addEventListener('resize', debouncedDidResize);
			setDidResize(() => debouncedDidResize);
			return () => {
				window.removeEventListener('resize', debouncedDidResize);
			};
		}
		return () => { };
	}, [carousel, breakpoint]);

	const infinite = rest.infinite || false;

	const bottomMarginsMap = {
		Large: '-40px',
		Medium: '-25px',
		Small: '25px',
		None: '25px',
	};

	let items = getFlatChildren(content, didResize);
	let itemsVisible = breakpoint.rows * breakpoint.slidesToShow * breakpoint.slidesPerRow;

	return (
		<div
			ref={containerRef}
			className={clsx(classes.root, {
				[classes.arrowsContained]: slider.arrowsContained,
				[classes.multiple]: rest.slidesToShow > 1 && !slider.arrowsContained,
				[classes.single]: !(rest.slidesToShow > 1),
				[classes.withDots]: breakpoint.dots
			})}
			data-items-visible={rest.slidesToShow || 1}
		>
			{(items.length > 1) && (
				<IconButton
					onClick={() => carousel.slickPrev()}
					className={clsx(classes.button, classes.previousButton)}
					disabled={!infinite && currentIndex == 0}
				>
					<Icon>keyboard_arrow_left</Icon>
				</IconButton>
			)}
			<Slider
				{...rest}
				// style={{ '--dots-bottom': bottomMarginsMap[bottomMargin] }}
				className={clsx(classes[`withBottomMargin-${bottomMargin}`])}
				arrows={false}
				afterChange={setCurrentIndex}
				ref={setCarousel}
				swipe
			>
				{items}
			</Slider>
			{(items.length > 1) && (
				<IconButton
					onClick={() => carousel.slickNext()}
					className={clsx(classes.button, classes.nextButton)}
					disabled={!infinite && currentIndex == (items.length - itemsVisible)}
				>
					<Icon>keyboard_arrow_right</Icon>
				</IconButton>
			)}
		</div>
	);
};

// This is to interact with Slick, when it changes up the size of it's content...
class Slide extends Component {
	constructor(...args) {
		super(...args);
		this._gotRoot = this._gotRoot.bind(this);
		this._nodeChanged = this._nodeChanged.bind(this);
		this.state = this._newState();
	}

	_newState() {
		let r = {};
		const rv = {
			value: new Promise((resolve, reject) => {
				r = { resolve, reject };
			}),
		};
		rv.resolve = r.resolve;
		rv.reject = r.reject;
		return rv;
	}

	_gotRoot(node) {
		if (this._root != node) {
			if (this._root) this._unbind();
			this._root = node;
			if (this._root) this._bind();
		}
	}

	_unbind() {
		this._observer.disconnect();
		this._observer = null;
	}

	_bind() {
		this._observer = new MutationObserver(this._nodeChanged);
		let tgt = this._root;
		do {
			if (tgt.classList && tgt.classList.contains('slick-slide')) {
				break;
			}
			tgt = tgt.parentNode;
		} while (tgt);
		if (tgt) {
			this._observer.observe(tgt, {
				attributeFilter: ['style'],
				attibutes: true,
			});
		}
	}

	_nodeChanged() {
		this.props.onResize().then(value => {
			this.state.resolve(value);
			this.setState(this._newState());
		});
	}

	componentWillUnmount() {
		this.state.reject(true);
	}

	getContent() {
		if (this.props.getRawNode) {
			return this.props.getRawNode({ resized: this.state.value, shouldResize: this._nodeChanged });
		}
		return getChildren([ { ...this.props.node, resized: this.state.value, shouldResize: this._nodeChanged } ]);
	}

	render() {
		return (
			<div ref={this._gotRoot}>
				<div data-item>
					{this.getContent()}
				</div>
			</div>
		);
	}
}

const useStyles = makeStyles(
	theme => ({
		root: {
			position: 'relative',
			'&$single .slick-slider [data-item] > *': {
				[theme.breakpoints.up('md')]: {
					paddingLeft: theme.spacing(8),
					paddingRight: theme.spacing(8),
				},
			},
			'& [data-item]': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				justifyContent: 'stretch',
				'& > *': {
					flex: 1,
				},
			},
			'& .slick-dots': {
				// bottom: 'var(--dots-bottom)',
				'& button::before': {
					[theme.breakpoints.up('md')]: {
						fontSize: '12px',
					},
					borderStyle: 'solid',
					borderRadius: '50%',
					borderColor: 'white',
					backgroundColor: 'rgba(0,0,0,0.2)',
					color: 'rgba(0,0,0,0)',
				},
				'& .slick-active': {
					'& button::before': {
						color: 'rgba(0,0,0,0)',
						opacity: '0.9',
					},
				},
				// Set a default
				bottom: 25
			},
		},
		'withBottomMargin-Large': {
			'& .slick-dots': {
				bottom: '-40px',
				[theme.breakpoints.down('xs')]: {
					bottom: '-25px',
				},
			},
		},
		'withBottomMargin-Medium': {
			'& .slick-dots': {
				bottom: '-25px',
				[theme.breakpoints.down('xs')]: {
					bottom: '-15px',
				},
			},
		},
		'withBottomMargin-Small': {
			'& .slick-dots': {
				bottom: '25px',
			},
		},
		'withBottomMargin-None': {
			'& .slick-dots': {
				bottom: '25px',
			},
		},
		single: {},
		button: {
			top: '50%',
			position: 'absolute',
			transform: 'translateY(-50%)',
			backgroundColor: 'rgba(255,255,255,0.4)',
			zIndex: 1,
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
			'&:hover': {
				backgroundColor: 'rgba(255,255,255,1)',
			},
		},
		previousButton: {
			left: theme.spacing(1),
		},
		nextButton: {
			right: theme.spacing(1),
		},
		arrowsContained: {
			padding: 0,
			background: 'unset',
		},
		multiple: {
			display: 'grid',
			gridTemplateColumns: `${theme.spacing(8)}px calc(100% - ${theme.spacing(16)}px) ${theme.spacing(8)}px`,
			alignItems: 'center',
			'& > .slick-slider': {
				flex: '1 1 auto',
				gridColumn: 2,
			},
			'& > $button': {
				position: 'relative',
				transform: 'none',
				top: 0,
				left: 0,
				right: 0,
				alignSelf: 'center',
				justifySelf: 'center',
			},
			'& > $previousButton': {
				gridColumn: 1,
			},
			'& > $nextButton': {
				gridColumn: 3,
			},
		},
		withDots: {
			'& .slick-list [data-item]': {
				// paddingBottom: 65
			}
		}
	}),
	{ name: 'SwCarousel' }
);

const ThemedCarouselBlock = ThemeLoader(StyleLoader(CarouselBlock, useStyles));

export default ThemedCarouselBlock;
