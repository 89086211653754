export default theme => ({
	root: {
		padding: theme.spacing(8),

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',

		'&:not([class*=SWRecordHeader-root])': {
			justifyContent: 'center',
		},

		'& [class*=SWFreeform-root-]': {
			'& h1': {
				marginBottom: 0,
			},
			'& > :first-child': {
				marginBottom: 0,
			},
			'& > :last-child': {
				marginTop: 0,
			},
		},

		'& [class*=media-embed-root-]': {
			width: '100%',
			height: '100%',
		},

		'& .MuiButton-root, & [class*=MuiButton-root-]': {
			flex: '0 0 auto',
			alignSelf: 'flex-start',
		},
		'& .MuiButton-text, & [class*=MuiButton-text-]': {
			paddingLeft: 0,
			paddingRight: 0,
		},
		'& .MuiTypography-h6, & [class*=MuiTypography-h6-], & h6': {},
		'& .MuiTypography-h5, & [class*=MuiTypography-h5-], & h5': {},
		[theme.breakpoints.only('xs')]: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			minHeight: 600,
			'& .MuiTypography-root, & [class*=MuiTypography-root-]': {
				width: '100%',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: 48,
			},
		},
		[theme.breakpoints.only('sm')]: {
			minHeight: 450,
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			'& .MuiTypography-root, & [class*=MuiTypography-root-]': {
				width: '100%',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: 64,
			},
		},
		[theme.breakpoints.only('md')]: {
			minHeight: 600,
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(8),
			paddingRight: theme.spacing(8),
			'& .MuiTypography-root, & [class*=MuiTypography-root-]': {
				width: '100%',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: '5rem',
			},
		},
		[theme.breakpoints.only('lg')]: {
			minHeight: 768,
			'& .MuiTypography-root, & [class*=MuiTypography-root-]': {
				maxWidth: 980,
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: '5rem',
			},
		},
		[theme.breakpoints.only('xl')]: {
			minHeight: 1000,
			'& .MuiTypography-root, & [class*=MuiTypography-root-]': {
				maxWidth: 1100,
			},
		},
		'& > .MuiTypography-root, & > [class*=MuiTypography-root-]': {
			'& ~ .MuiTypography-root:last-child, & ~ [class*=MuiTypography-root-]:last-child': {
				[theme.breakpoints.up('md')]: {
					width: 'max-content',
				},
			},
			'& ~ .MuiButton-root:last-child, & ~ [class*=MuiButton-root-]:last-child': {
				marginTop: theme.spacing(4),
				position: 'relative',
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				marginLeft: -theme.spacing(2),
			},
		},
		'& [class*=SWRecordHeader-subTitle-][class*=MuiTypography-h1-]': {
			// At big sizes, h1 looks to have extra left spacing. Pull it back out.
			marginLeft: '-0.05em',
		},
		'& .transition-init': {
			webkitTransition: 'all 0.6s ease-in-out',
			transition: 'all 0.6s ease-in-out',
			opacity: 1,
			webkitTransform: 'translateY(30px)',
			transform: 'translateY(30px)',
		},
		'& .transition-fade': {
			opacity: 0,
		},
	},
	initFadeIn: {
		'& *': {
			opacity: 0,
			'& img': {
				opacity: 1,
			},
			'& [class*=SWRecordHeader-images-]': {
				opacity: 1,
			},
		},
		'& [class*=SWRecordHeader-inner-]': {
			opacity: 1,
		},
	},
	runFadeIn: {
		'& *': {
			opacity: 1,
		},
	},
	initSlideUp: {
		'& *': {
			webkitTransform: 'translateY(30px)',
			transform: 'translateY(30px)',
			'& img': {
				webkitTransform: 'translateY(-30px)',
				transform: 'translateY(-30px)',
			},
			'& [class*=SWRecordHeader-images-]': {
				webkitTransform: 'unset',
				transform: 'unset',
			},
		},
	},
	runSlideUp: {
		'& *': {
			webkitTransform: 'translateY(0px)',
			transform: 'translateY(0px)',
			'& img': {
				webkitTransform: 'translateY(0px)',
				transform: 'translateY(0px)',
			},
		},
	},
	initTransition: {
		'& *': {
			webkitTransition: 'all 0.6s ease-in-out',
			transition: 'all 0.6s ease-in-out',
		},
	},
});
