import Flipped from './flipped';
import Dark from './dark';
import Default from './default';

export default {
	'flipped': {
		title: 'Invert primary & secondary colors',
		theme: Flipped
	},
	'dark': {
		title: 'Dark',
		theme: Dark
	},
	'default': {
		title: 'Default theme',
		theme: Default
	}
};

