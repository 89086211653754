const isCached = imgSrc => {
	const img = new Image();
	img.src = imgSrc;

	return img.complete;
};

const cacheImage = imgSrc => {
	const img = new Image();
	return new Promise((resolve, reject) => {
		img.onload = () => resolve(img.src);
		img.onerror = () => reject(img.src);
		img.src = imgSrc;
	});
}

export default isCached;
export { cacheImage };

