import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

let Loaded = false;
const CalendlyWidgetScript = ({ loaded }) => {
	let ref = useRef(null);
	useEffect(() => {
		if (!Loaded) {
			let n = document.createElement('script');
			n.async = true;
			n.defer = true
			n.src = 'https://assets.calendly.com/assets/external/widget.js';
			n.onload = () => {
				Loaded = true;
				loaded();
			}
			ref.current.appendChild(n);
		}
	}, []);

	return <span ref={ref}/>;
};

const CalendlyWidget = ({ url }, ref) => {
	let [ scriptReady, setScriptReady ] = useState(false);
	let node = useRef(null);
	let callback = useCallback(() => {
		setScriptReady(true);
	}, []);
	
	useEffect(() => {
		if (scriptReady) {
			Calendly.initInlineWidget({
				url,
				parentElement: node.current,
				inlineStyles: false
			});
		}
	}, [ scriptReady ]);

	return (
		<>
			<CalendlyWidgetScript loaded={callback}/>
			<div ref={node} className='calendly-inline-widget' data-auto-load='false' data-url={url} style={{ minWidth: '320px', height: '630px' }}/>
		</>
	);
}

export default React.forwardRef(CalendlyWidget);
