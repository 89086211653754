import Banner from './banner/browser';
import Button from './button/browser';
import Card from './card/browser';
import Details from './details/browser';
import Summary from './summary/browser';
import NavButton from './nav-button/browser';
import Tile from './tile/browser';
import Avatar from './avatar/browser';
import AddEventButton from './add-event-button/browser';
import ShareFab from './share-fab/browser';
import Download from './download/browser';

export default {
	banner: Banner,
	button: Button,
	card: Card,
	details: Details,
	summary: Summary,
	'nav-button': NavButton,
	tile: Tile,
	avatar: Avatar,
	'add-event-button': AddEventButton,
	'share-fab': ShareFab,
	download: Download,
};
